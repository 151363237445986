import { ApplicationController } from './application_controller.js'
import TomSelect from 'tom-select'
import { api } from '../components/AxiosHeaders.js'
export default class extends ApplicationController {
  static targets = ['search','userId']
  static values = { url: String }
  
  connect() {
    this.build()
  }

  build() {
    const controller = this 
    const tsOptions = {
      maxOptions: null,
      maxItems: 1,
      hidePlaceholder: true,
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      sortField: [{ field: '$order' }, { field: '$score' }],
      load: function(query, callback) {
        api.get(controller.urlValue, { params: { q: query } })
        .then((response) => callback(response.data.users))
        .catch((error) => {
          console.log(error)
          callback()
        })
      }
    }
    this.searchTarget.classList.remove("form-control")
    this.tomselect = new TomSelect(this.searchTarget, tsOptions)
  }
}
